<template lang="pug">
	.position-relative(@click="focusMyInputDropdown")
		.d-flex.position-relative.my-input-dropdown(:class="[{'is-focused': isFocused, 'is-float': value && !isFocused, 'is-error': errorFeedback != '' || isError, 'no-icon': icon == ''}, icon, classInput]")
			input.b3.w-100.my-input-dropdown__input(
				ref="myInputDropdown"
				autocomplete='off'
				@input="$emit('input', $event.target.value), isShowList = true"
				:value="value"
				:type="type"
				:id="`input-${_uid}`"
				@focus="isFocused = true, $emit('focus')"
				@blur="isFocused = false, $emit('blur')"
			)
			label.m-0.b3.my-input-dropdown__label(:class="{'b4': isFocused || value}") {{ label }}
			icon-close.my-input-dropdown__reset(v-if="value" @click.prevent="$emit('input', ''), $emit('onClear', '')")
			svg.cursor-pointer.my-input-dropdown__reset-error(@click.prevent="$emit('input', '')" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none")
				path(d="M17.4168 9.4987C17.4168 5.14453 13.8543 1.58203 9.50016 1.58203C5.146 1.58203 1.5835 5.14453 1.5835 9.4987C1.5835 13.8529 5.146 17.4154 9.50016 17.4154C13.8543 17.4154 17.4168 13.8529 17.4168 9.4987Z" stroke="#FF7C7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				path(d="M7.25977 11.7386L11.7406 7.25781" stroke="#FF7C7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				path(d="M11.7406 11.7386L7.25977 7.25781" stroke="#FF7C7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
		.text-danger.b4.mt-1(v-if="errorFeedback != ''" v-html='errorFeedback')
		.my-input-dropdown__list(v-if="value && value.length > 3 && isShowList")
			.my-input-dropdown__item(v-for='(item, i) in list' :key='i' @click="selectValue(item)" )
				span.b3(v-if='item.unrestricted_value' v-html="item.unrestricted_value")
				span.b3(v-else v-html="item.value")
				svg.ml-3(v-if="value == item.value" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg")
					path(d="M2.29883 9.24023L5.68129 12.9609L14.7012 3.03906" stroke="#59CB94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
			.d-flex.flex-column.py-5(v-if="list.length == 0")
				h1.mb-3.text-center 😢
				p.b4.mb-0.text-grey.text-center {{ $t('errors.emptySearch') }}
</template>

<script>
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'MyInputDropdown',
    components: {
        IconClose,
    },
    props: {
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            default: '',
        },
        errorFeedback: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        value: {
            default: null,
        },
        classInput: {
            default: '',
        },
        isError: {
            type: Boolean,
            default: false,
        },
        list: {
            default: () => [],
            type: Array,
        },
    },
    data: () => ({
        isFocused: false,
        isShowList: false,
    }),
    created() {
        window.addEventListener('click', e => {
            if (this.isShowList === true) {
                const target = e.target;

                if (!target.closest('.my-input-dropdown') && !target.closest('.my-input-dropdown__list')) {
                    this.isShowList = false;
                }
            }
        }),
            window.addEventListener('keydown', e => {
                if (e.code == 'Tab') {
                    this.isShowList = false;
                }
            });
    },
    methods: {
        selectValue(item) {
            this.value = item.unrestricted_value ? item.unrestricted_value : item.value;
            this.$emit('select', this.value);
            this.isShowList = false;
        },
        focusMyInputDropdown() {
            this.$refs.myInputDropdown.focus();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.my-input-dropdown {
    background-color: var(--bg-back);
    border-radius: var(--border-radius-rounded);

    &::before {
        z-index: 2;
        position: absolute;
        content: '';
        top: 50%;
        left: 20px;
        background-color: var(--primary-text-color);
        mask-repeat: no-repeat;
        mask-size: cover;
        transform: translateY(-50%);
    }

    &::after {
        z-index: 1;
        position: absolute;
        content: none;
        top: 50%;
        right: 20px;
        background-size: cover;
        transform: translateY(-50%);
    }

    &.icon-lightning {
        &::before {
            width: 20px;
            height: 23px;
            mask-image: url('/assets/img/icons/lightning.svg');
        }
    }

    &.icon-usage {
        &::before {
            width: 24px;
            height: 24px;
            mask-image: url('/assets/img/icons/usage.svg');
        }
    }

    &.icon-discount {
        &::before {
            width: 24px;
            height: 24px;
            mask-image: url('/assets/img/icons/discount.svg');
        }
    }

    &.icon-money {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/money.svg');
        }
    }

    &.icon-sms {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/sms.svg');
        }
    }

    &.icon-search {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/search.svg');
        }
    }

    &.icon-link {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/link.svg');
        }
    }

    &.icon-archive-book {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/archive-book.svg');
        }
    }

    &.icon-home-hashtag {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/home-hashtag.svg');
        }
    }

    &.icon-wallet {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/wallet.svg');
        }
    }

    &.icon-card-tick {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/card-tick.svg');
        }
    }

    &.icon-profile {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/profile.svg');
        }
    }

    &.icon-call {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/call.svg');
        }
    }

    &.icon-document {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/document.svg');
        }
    }

    &.icon-house {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/house.svg');
        }
    }

    &.icon-terms-of-use {
        &::before {
            width: 20px;
            height: 20px;
            mask-image: url('/assets/img/icons/terms-of-use.svg');
        }
    }

    &.is-focused {
        background-color: var(--foreground-color);

        .my-input-dropdown__input {
            padding: 24px 40px 6px 50px;
            color: var(--main-text-color);
        }

        .my-input-dropdown__label {
            top: 30%;
        }
    }

    &.is-float {
        background-color: var(--bg-back);

        .my-input-dropdown__input {
            padding: 24px 40px 6px 50px;
            color: var(--main-text-colorr);
        }

        .my-input-dropdown__label {
            top: 30%;
        }
    }

    &.is-error {
        background-color: transparent;

        .my-input-dropdown__input {
            border: 1.5px solid var(--brand-error) !important;

            &:hover,
            &:focus {
                border: 1px solid var(--brand-error) !important;
            }
        }

        .my-input-dropdown__label {
            color: var(--brand-error);
        }

        &::before {
            background-color: var(--red-text-color);
        }
    }

    &.is-error-icon {
        background-color: transparent;

        .my-input-dropdownt__input {
            border: 1.5px solid var(--brand-error) !important;

            &:hover,
            &:focus {
                border: 1px solid var(--brand-error) !important;
            }
        }

        .my-input-dropdown__label {
            color: var(--brand-error);
        }

        .my-input-dropdown__reset {
            display: none;
        }

        .my-input-dropdown__reset-error {
            z-index: 1;
            position: absolute;
            top: 50%;
            right: 19px;
            display: block;
            width: 19px;
            height: 19px;
            transform: translateY(-50%);
            transition: var(--animation-time-short);

            &:hover,
            &:active {
                opacity: 0.7;
            }
        }
    }

    &.is-success-icon {
        background-color: transparent;

        .my-input-dropdownt__input {
            border: 1.5px solid var(--brand-success) !important;

            &:hover,
            &:focus {
                border: 1px solid var(--brand-success) !important;
            }
        }

        .my-input-dropdown__label {
            color: var(--brand-success);
        }

        &::after {
            content: '';
            width: 19px;
            height: 19px;
            mask-image: url('/assets/img/icons/tick-circle.svg');
            background-color: var(--brand-success);
        }

        .my-input-dropdown__reset {
            display: none;
        }
    }

    &__input {
        z-index: 1;
        align-items: center;
        height: 52px;
        padding: 15px 40px 15px 50px;
        border: 1.5px solid transparent;
        background-color: transparent;
        border-radius: var(--border-radius-rounded);
        transition: var(--animation-time-short);
        cursor: text;

        &:hover {
            background-color: transparent !important;
            border: 1.5px solid var(--button-border-color) !important;
        }

        &:focus {
            background-color: transparent !important;
            border: 1.5px solid var(--button-border-color) !important;
        }
    }

    &__label {
        position: absolute;
        top: 50%;
        left: 50px;
        color: var(--grey-text-color);
        transform: translateY(-50%);
        cursor: text;
        transition: var(--animation-time-short);
        z-index: 1;
    }

    &__reset {
        z-index: 1;
        position: absolute;
        top: 50%;
        right: 19px;
        transform: translateY(-50%);
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }

    &__reset-error {
        display: none;
    }

    &__text {
        position: absolute;
        bottom: 6px;
        left: 70px;
    }

    &.no-icon {
        .my-input-dropdown__input {
            padding: 24px 40px 6px 24px;
        }

        .my-input-dropdown__label {
            left: 24px;
        }

        &.is-focused {
            .my-input__input {
                padding: 24px 40px 6px 24px;
            }
        }

        &.is-float {
            .my-input__input {
                padding: 24px 40px 6px 24px;
            }
        }
    }

    &__list {
        position: absolute;
        z-index: 3;
        top: 58px !important;
        width: 100%;
        height: max-content;
        max-height: 186px;
        background-color: var(--background-color) !important;
        border-radius: var(--border-radius-rounded) !important;
        border: 1px solid var(--border-line);
        box-shadow: var(--box-shadow) !important;
        overflow-y: auto;
    }

    &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //text-transform: uppercase;
        padding: 10px 20px !important;
        color: var(--main-text-color) !important;
        background-color: var(--foreground-back);
        transition: var(--animation-time-short);
        cursor: pointer;

        &::after {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 0;
            height: 0.5px;
            background-color: var(--border-line);
        }

        &:hover,
        &:active,
        &:focus {
            background-color: var(--grayscale-bg-dark-back) !important;
            color: var(--main-text-color) !important;
        }

        svg {
            min-width: 17px;
            min-height: 17px;
        }
    }
}
</style>
